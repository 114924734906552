import { Suspense, lazy } from 'react';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRequestPage = Loadable(
  lazy(() => import('./pages/authentication/PasswordRequestPage'))
);
const PasswordResetPage = Loadable(
  lazy(() => import('./pages/authentication/PasswordResetPage'))
);

//Evaluations
const Evaluation = Loadable(
  lazy(() => import('./pages/Evaluation/Evaluation'))
);
//Jobs
const JobListing = Loadable(lazy(() => import('./pages/Jobs/JobListing')));
const GeneralJobListing = Loadable(
  lazy(() => import('./pages/Jobs/GeneralJobListing'))
);
const JobSingle = Loadable(lazy(() => import('./pages/Jobs/JobSingle')));
const JobApply = Loadable(lazy(() => import('./pages/Jobs/JobApply')));
// Error pages

const AuthorizationRequired = Loadable(
  lazy(() => import('./pages/Errors/AuthorizationRequired'))
);
const ResourceExpired = Loadable(
  lazy(() => import('./pages/Errors/ResourceExpired'))
);
const NotFound = Loadable(lazy(() => import('./pages/Errors/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/Errors/ServerError')));

const routes = [
  {
    path: 'evaluation',
    children: [
      {
        path: ':evaluationId',
        element: <Evaluation />
      }
    ]
  },
  {
    path: '*',
    children: [
      {
        path: '',
        element: <JobListing />
      },
      {
        path: ':entity',
        element: <GeneralJobListing />
      },
      {
        path: 'apply/:jobId',
        element: <JobApply />
      }
    ]
  },
  {
    path: 'jobs',
    children: [
      {
        path: '',
        element: <JobListing />
      },
      {
        path: ':jobId',
        element: <JobSingle />
      },
      {
        path: 'apply/:jobId',
        element: <JobApply />
      }
    ]
  },
  {
    path: 'error',
    children: [
      {
        path: '403',
        element: <AuthorizationRequired />
      },
      {
        path: '410',
        element: <ResourceExpired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '505',
        element: <ServerError />
      }
    ]
  }
];

export default routes;
