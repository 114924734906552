import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';
const store = createStore(rootReducer, applyMiddleware(thunk));

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
