import axios from 'axios';
import handleError from '../utils/handleError';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 20000
});

axiosInstance.interceptors.request.use(
  (config) => {
    const userDetails = localStorage.getItem('userDetails');
    if (userDetails) {
      const user = JSON.parse(userDetails);
      const token = user.token;
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(handleError(error));
  }
);

export default axiosInstance;
