import jwt_decode from 'jwt-decode';
import handleError from '../utils/handleError';
import axiosInstance from './axios';

class AuthApi {
  login(data) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post('/api/v1/auth/login', data)
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  }
  recoverPassword(password, token) {
    const userInformation = {};
    userInformation.password = password;
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(`/api/v1/auth/recover-password/${token}`, { userInformation })
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  }
  checkResetPasswordToken(token) {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/auth/check-token/${token}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  }
  requestPassword(data) {
    const userInformation = {};
    userInformation.email = data;
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch('/api/v1/auth/request-password', { userInformation })
        .then((user) => {
          resolve(user);
        })
        .catch((error) => {
          reject(handleError(error));
        });
    });
  }
  me(response) {
    return new Promise((resolve, reject) => {
      try {
        const data = response.data;
        // Decode access token
        const decoded = jwt_decode(data.token);
        if (!decoded) {
          reject(handleError('Invalid authorization token'));
          return;
        }

        resolve({
          id: decoded.userId,
          avatar: data.avatar,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          role: data.role,
          token: data.token
        });
      } catch (err) {
        console.error('[Auth Api]: ', err);
        reject(handleError);
      }
    });
  }
}

export const authApi = new AuthApi();
