import i18next from 'i18next';

const handleError = (error = null) => {
  let errorText = '';
  if (error.response) {
    switch (error.response.status) {
      case 500:
        errorText = i18next.t(error.response.data);
        break;
      case 400:
      case 409:
        errorText = i18next.t(error.response.data);
        break;
      case 401:
        errorText = i18next.t(error.response.data);
        break;
      case 403:
        break;
      case 404:
        errorText = i18next.t(error.response.data);
        break;
    }
  } else if (error.code) {
    switch (error.code) {
      case 'ECONNABORTED':
        errorText = errorText = i18next.t('Timeout');
        break;
    }
  } else if (error) {
    errorText = i18next.t(error);
  } else {
    errorText = i18next.t('Internal server error');
  }

  return new Error(errorText.toString());
};

export default handleError;
